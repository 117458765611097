import React, { useState, useEffect } from "react";
import "./footer.css";
import whatsappLogo from "../assets/whatsapp-logo.png";

const Footer = () => {
  const [minutes, setMinutes] = useState(15);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let timer;

    if (minutes === 0 && seconds === 0) {
      clearInterval(timer);
    } else {
      timer = setInterval(() => {
        if (seconds === 0) {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [minutes, seconds]);

  return (
    <footer>
      <div className="footer-content-div">
        <a
          href="https://rzp.io/l/phBlT9OqT"
          target="_blank"
          rel="noreferrer"
          className="offer-button"
        >
          <button>BOOK NOW</button>
        </a>

        <h3 className="footer-timer-div">
          Seasonal Special Offer Ends in{" "}
          <span class="minutes" className="time">
            {String(minutes).padStart(2, "0")}
          </span>
          <span>:</span>
          <span class="seconds" className="time">
            {String(seconds).padStart(2, "0")}
          </span>{" "}
          Mins
        </h3>
      </div>
      <a
        href="https://wa.me/919345841687"
        className="whatsapp-link"
        title="WhatsApp Us"
        target="_blank"
        rel="noreferrer"
      >
        <img src={whatsappLogo} alt="whatsapp-link" />
      </a>
    </footer>
  );
};

export default Footer;
